import './App.css'
import { Button, Space } from 'antd'
import { Content } from 'antd/es/layout/layout'
import locales from './locales/locale'
import servizi from './images/servizi.jpg'
import materiali from './images/materiali.png'
import settori from './images/settori.png'
import { memo } from 'react'
import { Slide } from 'react-awesome-reveal'

export const Home = ({ lang }: { lang: string }) => {
  return (
    <Content style={{ zIndex: 1, overflow: 'hidden' }}>
      <div className="background">
        <Space
          className="primoParagrafo"
          direction="vertical"
          align="start"
          size="small"
        >
          <h2>Mas Metal S.R.L.</h2>
          <p>{locales.paragrafo1}</p>
        </Space>
      </div>
      <div className="paragrafo" style={{ minHeight: '50vh' }}>
        <div style={{ width: '90%', paddingBottom: '5vw' }}>
          <p style={{ fontSize: '1.5vw' }}>{locales.paragrafo2}</p>
        </div>
        {/* <div
            className="pallini"
            style={{
              textAlign: 'center',
              width: '90%',
              display: 'flex',
            }}
          >
            <img
              className="img-style"
              alt="By Pixebay user"
              width={'100%'}
              src={tubi}
            />
          </div> */}
        {/* <img alt="By Karolina Grabowska" src={team} /> */}
      </div>
      <div>
        <Slide>
          <h1 className="titoloCaino"> {locales.paragrafo3.titolo}</h1>
        </Slide>
        <div className="paragrafo sfondoScuro reverse">
          <div style={{ width: '90%', paddingBottom: '5vw' }}>
            <p style={{ fontSize: '1.5vw' }}>
              {locales.paragrafo3.descrizione}
            </p>
          </div>
          <div
            className="pallini"
            style={{
              textAlign: 'center',
              width: '90%',
              display: 'flex',
            }}
          >
            <img
              className="img-style"
              alt="By Pixebay user"
              width={'100%'}
              src={servizi}
            />
          </div>
          {/* <img alt="By Karolina Grabowska" src={team} /> */}
        </div>
      </div>
      <div>
        <Slide direction="right">
          <h1 className="titoloCaino inverso">{locales.paragrafo4.titolo}</h1>
        </Slide>
        <div className="paragrafo reverse">
          <div style={{ width: '90%', paddingBottom: '5vw' }}>
            <p style={{ fontSize: '1.5vw' }}>
              {locales.paragrafo4.descrizione}
            </p>
          </div>
          <div
            className="pallini"
            style={{
              textAlign: 'center',
              width: '90%',
              display: 'flex',
            }}
          >
            <img
              className="img-style"
              alt="By Pixebay user"
              width={'100%'}
              src={materiali}
            />
          </div>
          {/* <img alt="By Karolina Grabowska" src={team} /> */}
        </div>
      </div>

      <div>
        <Slide>
          <h1 className="titoloCaino"> {locales.paragrafo5.titolo}</h1>
        </Slide>
        <div className="paragrafo sfondoScuro reverse">
          <div style={{ width: '90%', paddingBottom: '5vw' }}>
            <p style={{ fontSize: '1.5vw' }}>
              {locales.paragrafo5.descrizione}
            </p>
          </div>
          <div
            className="pallini"
            style={{
              textAlign: 'center',
              width: '90%',
              display: 'flex',
            }}
          >
            <img
              className="img-style"
              alt="By Pixebay user"
              width={'100%'}
              src={settori}
            />
          </div>
          {/* <img alt="By Karolina Grabowska" src={team} /> */}
        </div>
      </div>
      <div>
        <Slide direction="right">
          <h1 className="titoloCaino inverso">{locales.paragrafo6.titolo}</h1>
        </Slide>
        <div className="paragrafo reverse" style={{ minHeight: '50vh' }}>
          <div style={{ width: '90%', paddingBottom: '5vw' }}>
            <p style={{ fontSize: '1.5vw' }}>
              {locales.paragrafo6.descrizione}
            </p>
            <Space wrap align="center" className="certificazioniBottone">
              <Button
                href="/certificazioni/certificazione_1.pdf"
                download
                size="large"
                type="primary"
              >
                {locales.paragrafo6.button}
              </Button>
              <Button
                href="/certificazioni/certificazione_2.pdf"
                download
                size="large"
                type="primary"
              >
                {locales.paragrafo6.button}
              </Button>
            </Space>
          </div>

          {/* <img alt="By Karolina Grabowska" src={team} /> */}
        </div>
      </div>
    </Content>
  )
}

export default memo(Home)
