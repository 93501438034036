import {
  MailFilled,
  MailOutlined,
  MobileFilled,
  MobileOutlined,
  PhoneFilled,
  PhoneOutlined,
} from '@ant-design/icons'
import { Space } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { memo, useEffect, useState } from 'react'
import './../App.css'
import logo from '../images/logo_bianco.png'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const Menu = ({
  setLang,
  lang,
}: {
  setLang: React.Dispatch<React.SetStateAction<string>>
  lang: string
}): JSX.Element => {
  const [isFixed, setIsFixed] = useState(false)

  const { pathname } = useLocation()

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      setIsFixed(scrollTop > 0) // Imposta isFixed a true se lo scroll è maggiore di 0
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const isFixedClassName = isFixed || pathname !== '/' ? 'is-fixed' : ''

  return (
    <Header id="header" className={isFixedClassName}>
      <div className={`info-menu ${isFixedClassName}`}>
        <div style={{ float: 'right' }}>
          <Space
            style={{
              borderRight: '1px #ffffff3d solid',
              padding: '0 1vw',
            }}
            size={'small'}
          >
            <PhoneOutlined />
            <a href="tel:+390307998505" style={{ color: 'white' }}>
              +39 0307998505
            </a>
          </Space>
          <Space
            style={{
              borderRight: '1px #ffffff3d solid',
              padding: '0 1vw',
            }}
            size={'small'}
          >
            <MobileOutlined />
            <a href="tel:+393482920338" style={{ color: 'white' }}>
              +39 3482920338
            </a>
          </Space>
          <Space
            style={{
              padding: '0 1vw',
            }}
            size={'small'}
          >
            <MailOutlined />
            <a href="mailto:info@masmetalsrl.com" style={{ color: 'white' }}>
              info@masmetalsrl.com
            </a>
          </Space>
        </div>
      </div>
      <Link to="/">
        <Space
          direction="horizontal"
          className="menu-items"
          // align="start"
          style={{
            padding: 8,
            color: isFixed || pathname !== '/' ? 'black' : 'white',
          }}
        >
          <img src={logo} alt="Sfondo" className={`logo ${isFixedClassName}`} />
        </Space>
      </Link>
      <Space className={`lingua ${isFixedClassName}`}>
        <a href="tel:+390307998505">
          <PhoneFilled />
        </a>
        <a href="tel:+393482920338">
          <MobileFilled />
        </a>
        <a href="mailto:info@masmetalsrl.com">
          <MailFilled />
        </a>
        {/* <Switch
          checked={lang === 'en'}
          onChange={(language) => {
            setLang(language ? 'en' : 'it')
            locales.setLanguage(language ? 'en' : 'it')
          }}
          checkedChildren="EN"
          unCheckedChildren="IT"
        /> */}
      </Space>
    </Header>
  )
}

export default memo(Menu)
