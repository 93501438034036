import LocalizedStrings from 'react-localization'

let locales = new LocalizedStrings({
  it: {
    paragrafo1:
      "La tua soluzione affidabile per il commercio all'ingrosso di sottoprodotti metallici e industriali.",
    paragrafo2:
      "Mas Metal S.R.L. è leader nel commercio all'ingrosso di sottoprodotti derivanti dalla lavorazione industriale e dei metalli. Offriamo soluzioni efficienti e personalizzate per soddisfare le esigenze di vari settori industriali, garantendo materiali di alta qualità e servizi affidabili.",
    paragrafo3: {
      titolo: 'Servizi',
      descrizione:
        'Mas Metal S.R.L. offre un’ampia gamma di servizi per supportare la tua attività industriale. Dalla raccolta e smistamento di sottoprodotti metallici alla lavorazione e distribuzione, ci occupiamo di tutte le fasi logistiche, garantendo soluzioni su misura e ottimizzando i tuoi processi produttivi.',
    },
    paragrafo4: {
      titolo: 'Materiali',
      descrizione:
        'Offriamo una vasta selezione di materiali metallici e industriali, tra cui acciaio, rame, alluminio e altri sottoprodotti riciclati. Sia che tu abbia bisogno di materie prime per la produzione o materiali di scarto per il riciclo, Mas Metal S.R.L. ti garantisce qualità e disponibilità costante.',
    },
    paragrafo5: {
      titolo: 'Settori',
      descrizione:
        "I nostri materiali e servizi trovano applicazione in una vasta gamma di settori, tra cui l'edilizia, l'industria manifatturiera, l'automotive e l'energia. Collaboriamo con aziende di diverse dimensioni, offrendo soluzioni che si adattano alle loro esigenze specifiche, contribuendo a ottimizzare le risorse e migliorare l'efficienza produttiva.",
    },
    paragrafo6: {
      titolo: 'Certificazioni',
      descrizione:
        'Mas Metal S.R.L. opera in conformità con i più alti standard di qualità e sicurezza. Le nostre certificazioni garantiscono che ogni materiale e processo rispetti rigorose normative ambientali e industriali, offrendo ai nostri clienti sicurezza e affidabilità in ogni transazione.',
      button: 'Scarica certificazione',
    },
    contact: 'Contattaci',
  },
})

export default locales
