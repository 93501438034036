import {
  EnvironmentOutlined,
  MailOutlined,
  MobileOutlined,
  PhoneOutlined,
} from '@ant-design/icons'
import './App.css'
import { Col, Layout, Row, Space } from 'antd'
import { Footer } from 'antd/es/layout/layout'
import Menu from './components/menu'
import locales from './locales/locale'
import { useState } from 'react'
import Home from './Home'
import { Route, Routes } from 'react-router-dom'

function App() {
  const [language, setLanguage] = useState(locales.getLanguage())

  return (
    <Layout>
      <Menu setLang={setLanguage} lang={language} />
      <Routes>
        <Route path="/" element={<Home lang={language} />} />
      </Routes>
      <Footer>
        <Row>
          <Col>
            <b>{locales.contact}</b>
          </Col>
        </Row>
        <div className="foot">
          <Row>
            <Col>
              <Space size={'small'}>
                <PhoneOutlined />
                <a href="tel:+390307998505" style={{ color: 'white' }}>
                  +39 0307998505
                </a>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col>
              <Space size={'small'}>
                <MobileOutlined />
                <a href="tel:+393482920338" style={{ color: 'white' }}>
                  +39 3482920338
                </a>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col>
              <Space size={'small'}>
                <MailOutlined />
                <a
                  href="mailto:info@masmetalsrl.com"
                  style={{ color: 'white' }}
                >
                  info@masmetalsrl.com
                </a>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col>
              <Space size={'small'}>
                <EnvironmentOutlined />
                <a
                  target="_blank"
                  href="https://www.google.com/maps/place/Via+Padana+Superiore,+87,+25045+Castegnato+BS/@45.5519852,10.1167895,18z/data=!4m6!3m5!1s0x4781710d14417e65:0x5806573e05ffe0eb!8m2!3d45.5515121!4d10.1179902!16s%2Fg%2F11c1_q7zpv?authuser=0&entry=ttu&g_ep=EgoyMDI0MDkxMC4wIKXMDSoASAFQAw%3D%3D"
                  style={{ color: 'white' }}
                  rel="noreferrer"
                >
                  Via Padana Superiore 87/A, Castegnato (BS), 25045
                </a>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginTop: '1vw' }}>
            <Col>
              <a
                style={{ color: 'white', fontWeight: 'bold' }}
                target="_blank"
                href="https://www.termsfeed.com/live/e06f65ad-cdb5-40fe-b4e2-9fac99e5481f"
                rel="noreferrer"
              >
                Privacy policy
              </a>
            </Col>
            <Col
              style={{
                padding: '0 1vw',
              }}
            >
              <a
                style={{ color: 'white', fontWeight: 'bold' }}
                target="_blank"
                href="https://www.termsfeed.com/live/9fafeb07-59ea-4afc-928f-905f1335a43a"
                rel="noreferrer"
              >
                Cookie policy
              </a>
            </Col>
          </Row>
        </div>
      </Footer>
    </Layout>
  )
}

export default App
